<template>
  <div class="relative z-30" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity backdrop-blur-sm"></div>
    <div class="fixed inset-0 z-10 overflow-y-auto">
      <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
        <div
          class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
          <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start">
              <div class="mt-3 text-left sm:mt-0 sm:ml-4">
                <h3 class="text-lg font-medium leading-6 text-gray-900" id="modal-title">{{ title }}</h3>
                <div class="mt-2 text-sm">
                  <Loading v-if="isLoading"/>
                  <div v-else v-html="_content" class="modal_content">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
            <button class="m-0 px-6 py-2 flex justify-center items-center
        border bg-main-green-75 text-white rounded-lg
        not-italic font-medium text-sm hover:bg-main-green" @click="$emit('confirm')">Ok
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {defineEmits} from 'vue'
import api from "@/libs/axios";
import Loading from "@/components/common/Loading";

export default {
  name: "ModalWindow",
  components: {Loading},
  //emits:['confirm', 'cancel'],
  props: {
    contentUrl: {
      default: '',
      type: String,
    },
    title: {
      default: '',
      type: String,
    },
    content: {
      default: '',
      type: String
    }
    /*question:{
      default:'Simply text',
      type:String,
    }*/
  },
  methods: {},
  mounted() {
    this.isLoading = true;
    this._content = this.content;
    api.get(this.contentUrl)
      .then((response) => {
        if (response.data && response.status == 200)
          this._content = response.data;
         this.isLoading = false;
      })
      .finally(() => {
         this.isLoading = false;
      });
  },
  setup() {

  },
  data() {
    let isLoading = false;
    return {
      isLoading,
      _content: '',
    }
  }
};
</script>

<style lang="scss" scoped>
.modal_content p{
  @apply text-main-blue-50;
}
.modal_content summary{
  font-weight: bold;
}

</style>
