<template>
  <aside
    class="sidebar z-20 fixed min-w-[292px] md:w-[302px] h-screen shadow shadow-grey-50"
    aria-label="Sidebar">
    <div class="overflow-y-auto relative py-4 bg-white h-full">
      <div class="flex flex-col w-full px-4 h-full" v-if="userData">
        <router-link to="/" class="flex items-center pl-2.5 mb-14">
          <img
            src="@/assets/logo.svg"
            class="mr-3 sm:h-12 w-full"
            alt="Flowbite Logo"
          />
        </router-link>
        <ul>
          <li class="bg-main-green-25 rounded-lg py-2 px-4 mb-4">
            <div
              class="
                flex
                justify-between
                place-items-center
                text-left text-xs text-main-blue
                gap-x-2
              ">
              <div class="avatar h-8 w-8
                bg-main-green-10
                border border-solid border-main-green-35 rounded-full
                bg-center">
              </div>
              <!--<router-link to="/settings">
                <img
                  src="@/assets/avatar/ava.png"
                  alt=""
                  class="w-full h-full"
                />
              </router-link>-->
              <div v-if="userData">
                <p class="font-bold text-[12px]">
                  {{
                    userData.name && userData.lastname
                      ? `${userData.name} ${userData.lastname}`
                      : userData.phone
                      ? userData.phone
                      : userData.email
                  }}
                </p>
                <p
                  v-if="userData.investor_qualification && userData.user_type == 5"
                  class="text-[10px]">
                  {{ userData.investor_qualification }}
                </p>
                <p v-if=" userData.user_type == 4"
                   class="text-[10px]">
                    {{userData.company_name}}
                </p>

              </div>
              <a
                href="#"
                class="font-bold text-main-green-75 hover:text-green"
                @click="onHandleLogout"
              >Выйти</a
              >
            </div>
          </li>
          <li>
            <!-- TODO: Вынести в отдельный компонент элемент меню, Заменить иконки на вектор -->
            <router-link
              to="/"
              :class="$route.path == '/' ? 'active' : ''"
              class="
                flex flex-row flex-nowrap
                items-center
                my-2
                px-5
                py-3.5
                text-sm
                font-medium
                text-default-color
                hover:bg-main-green-10 hover:text-main-blue
                rounded-lg
              "
            >
              <HomeIcon />
              <!--<img src="@/assets/icon/home.png" alt=""/>-->
              <span
                class="flex-1 whitespace-nowrap text-left ml-6"
                :class="$route.path == '/' ? 'text-main-blue font-medium' : ''"
              >
                Главная
              </span>
            </router-link>
          </li>
          <li>
            <router-link
              to="/requests"
              :class="$route.path == '/requests' ? 'active' : ''"
              class="
                flex flex-row flex-nowrap
                items-center
                my-2
                px-5
                py-3.5
                text-sm
                font-medium
                text-default-color
                hover:bg-main-green-10 hover:text-main-blue
                rounded-lg
              "
            >
              <!--<img src="@/assets/icon/rocket.png" alt=""/>-->
              <RocketIcon/>
              <span
                class="flex-1 whitespace-nowrap text-left ml-6"
                :class="
                  $route.path == '/requests' ? 'text-main-blue font-medium' : ''
                "
              >
                Проекты
              </span>
              <!--<span
                class="rounded-full text-[0.6rem] px-2.5 py-0"
                :class="
                  $route.path == '/requests'
                    ? 'text-main-green bg-white'
                    : 'bg-main-blue-10'
                "
              >
                123
              </span>-->
            </router-link>
          </li>
          <li v-if="userData.user_type == 5">
            <router-link
              to="/port"
              :class="$route.path == '/port' ? 'active' : ''"
              class="
                flex flex-row flex-nowrap
                items-center
                my-2
                px-5
                py-3.5
                text-sm
                font-medium
                text-default-color
                hover:bg-main-green-10 hover:text-main-blue
                rounded-lg
              "
            >
              <PortIcon/>
            <!--  <img src="@/assets/icon/port.png" alt=""/>-->
              <span
                class="flex-1 whitespace-nowrap text-left ml-6"
                :class="
                  $route.path == '/port' ? 'text-main-blue font-medium' : ''
                "
              >
                История
              </span>
              <!--<span
                class="rounded-full text-[0.6rem] px-2.5 py-0"
                :class="
                  $route.path == '/port'
                    ? 'text-main-green bg-white'
                    : 'bg-main-blue-10'
                "
              >
                123
              </span>-->
            </router-link>
          </li>
          <li v-if="userData.user_type == 2">
            <router-link
              to="/integrations"
              :class="$route.path == '/integrations' ? 'active' : ''"
              class="
                flex flex-row flex-nowrap
                items-center
                my-2
                px-5
                py-3.5
                text-sm
                font-medium
                text-default-color
                hover:bg-main-green-10 hover:text-main-blue
                rounded-lg
              "
            >
              <IntegrationIcon/>
              <span
                class="flex-1 whitespace-nowrap text-left ml-6"
                :class="
                  $route.path == '/integrations'
                    ? 'text-main-blue font-medium'
                    : ''
                "
              >
                Интеграции
              </span>
            </router-link>
          </li>
          <li v-if="userData.user_type == 2">
            <router-link
              to="/users"
              :class="$route.path == '/users' ? 'active' : ''"
              class="
                flex flex-row flex-nowrap
                items-center
                my-2
                px-5
                py-3.5
                text-sm
                font-medium
                text-default-color
                hover:bg-main-green-10 hover:text-main-blue
                rounded-lg
              "
            >
              <UserIcon/>
              <span
                class="flex-1 whitespace-nowrap text-left ml-6"
                :class="
                  $route.path == '/users' ? 'text-main-blue font-medium' : ''
                "
              >
                Пользователи
              </span>
            </router-link>
          </li>
          <li v-if="userData.user_type == 4">
            <router-link
              to="/documents"
              :class="$route.path == '/documents' ? 'active' : ''"
              class="
                flex flex-row flex-nowrap
                items-center
                my-2
                px-5
                py-3.5
                text-sm
                font-medium
                text-default-color
                hover:bg-main-green-10 hover:text-main-blue
                rounded-lg
              "
            >
              <UserIcon/>
              <span
                class="flex-1 whitespace-nowrap text-left ml-6"
                :class="
                  $route.path == '/documents'
                    ? 'text-main-blue font-medium'
                    : ''
                "
              >
                Документы
              </span>
            </router-link>
          </li>
          <li>
            <router-link
              to="/settings"
              :class="$route.path == '/settings' ? 'active' : ''"
              class="
                flex flex-row flex-nowrap
                items-center
                my-2
                px-5
                py-3.5
                text-sm
                font-medium
                text-default-color
                hover:bg-main-green-10 hover:text-main-blue
                rounded-lg
              "
            >
              <!--<img src="@/assets/icon/set.png" alt=""/>-->
              <SettingIcon/>
              <span
                class="flex-1 whitespace-nowrap text-left ml-6"
                :class="$route.path == '/settings' ? 'text-main-blue font-medium' : ''
                ">
                Настройки
              </span>
            </router-link>
          </li>
        </ul>
        <div class="w-full mt-auto text-sm">
          <ul>
            <!--<li class="rounded-lg py-2 px-4">
                <router-link to="#" class="flex items-center justify-start my-2">
                  <img class="mr-3" src="@/assets/icon/question.png" alt="" />
                  <span>Вопросы и ответы</span>
                </router-link>
              </li>-->
            <li class="bg-main-green-25 rounded-lg py-2 px-4">
              <a
                href="tel:+7 700 416 07 00"
                class="flex items-center justify-start my-2"
              >
                <img class="mr-3" src="@/assets/icon/phone.png" alt=""/>
                <span>+7 (700) 416 07 00</span>
              </a>
            </li>
          </ul>
        </div>
        <p class="text-xs mt-3">Сrowdlender 2022</p>
      </div>
    </div>
  </aside>
</template>

<script>
import {useAuth} from "@/store/auth";
import {useRouter} from "vue-router";
import {IntegrationIcon, UserIcon,HomeIcon,PortIcon,SettingIcon,RocketIcon} from "@/utils/svg";

export default {
  name: "Sidebar",
  components: {
    IntegrationIcon,
    UserIcon,
    HomeIcon,
    PortIcon,
    SettingIcon,
    RocketIcon
  },
  setup() {
    const {userData, onLogout} = useAuth();
    const router = useRouter();

    const onHandleLogout = () => {
      onLogout();
      router.push("/login");
    };

    return {
      userData,
      onHandleLogout,
    };
  },
};
</script>

<style scoped lang="scss">
.sidebar {
  background: linear-gradient(
      175.03deg,
      rgba(0, 0, 0, 0.1) 0%,
      rgba(0, 0, 0, 0.5) 100%
  ),
  #000;
  /*z-index: 111;*/
}

.avatar {
  background: url('@/assets/avatar/avatar.svg');
}

.active {
  background: linear-gradient(
      to right,
      theme("colors.main-green.DEFAULT") 0.5rem,
      theme("colors.main-green.10") 0.5rem,
      theme("colors.main-green.10")
  );
}

</style>
