<template>
  <div class="flex min-h-screen h-screen login-container m-0 p-0">
    <div
      class="md-hide w-full bg-cover bg-center max-h-full max-w-full relative "
      :style="{'background-image':`url(${require('@/assets/login/bg-login.png')})`}"
    >
      <div class="bg-white
          rounded-full
          shadow shadow-gray-500
          h-32 w-32
          h-auto w-auto
          absolute
          top-1/2 left-1/2 p-4
          -translate-x-2/4 -translate-y-2/4">
        <img class="h-full w-full" src="@/assets/base-logo.svg" alt="">
      </div>
    </div>
    <div
      class="
        min-h-full
        login-form-container
        flex
        items-center
        justify-center
        py-12
        px-4
        sm:px-6
        lg:px-8
        md-width-full
        w-7/12
        bg-white
      "
    >
      <div class="max-w-md w-full space-y-8">
        <div class="flex items-center justify-center">
          <router-link to="#" @click="isShowResetField = false" v-if="isShowResetField"
          ><img class="" src="@/assets/icon/left.png" alt=""
          /></router-link>
          <h2 class="text-center w-full text-3xl font-bold">Вход</h2>
        </div>
        <!-- <input type="hidden" name="remember" value="true" /> -->
        <div class="rounded-md shadow-sm mb-6" v-if="isShowResetField">
          <div class="mb-6">
            <label for="email-address" class="flex mb-1">Логин</label>
            <input
              v-model.trim="login"
              placeholder="Email или номер телефона"
              id="email-address"
              name="email"
              autocomplete="email"
              class="
                focus:text-gray-700
                focus:bg-white
                focus:border-green-600
                focus:outline-none
                focus:shadow-none border border-solid border-main-blue-50 rounded-lg box-border block w-full px-4 py-3 m-0 text-base font-normal text-main-blue bg-white bg-clip-padding transition  ease-in-out
              "
            />
          </div>

          <div class="flex">
            <button
              class="
                w-6/12 mr-2 py-2
                flex justify-center items-center
                border
                bg-main-green-75 text-white rounded-lg
                not-italic font-medium text-sm
                hover:bg-main-green
              "
              @click="onResetPassword"
            >
              <Spinner v-if="isLoading"/>
              <span v-else>Отправить</span>
            </button>

            <button
              @click="isShowResetField = false"
              class="
                button-link
                w-6/12 py-4 px-16
                flex justify-center
                bg-white text-main-green-50
                border border-solid border-main-green-50 rounded-lg
                not-italic font-medium text-sm
                hover:border-main-green
                hover:text-main-green
              "
            >
              Назад
            </button>
          </div>
        </div>
        <!-- <input type="hidden" name="remember" value="true" /> -->
        <div class="rounded-md shadow-sm mb-6 text-left" v-else>
          <div class="mb-6">
            <label for="email-address" class="flex justify-between mb-1">Логин <a href="#" @mouseenter="isHelp = true" v-if="!isHelp">Помощь</a></label>
            <input
              v-model.trim="login"
              placeholder="Адрес эл. почты или телефон"
              id="email-address"
              name="email"
              autocomplete="email"
              class="
                focus:text-gray-700
                focus:bg-white
                focus:border-green-600
                focus:outline-none
                focus:shadow-none border border-solid border-main-blue-50 rounded-lg box-border block w-full px-4 py-3 m-0 text-base font-normal text-main-blue bg-white bg-clip-padding transition  ease-in-out
              "
            />
            <span class="text-gray-400 text-xs" v-if="isHelp">пример:770012345678 или email@example.com</span>
          </div>
          <div>
            <label for="password" class="flex justify-between mb-1">
              Пароль
              <a href="#" @click="isShowResetField = true">Забыли пароль?</a>
            </label>
            <div class="relative">
              <input
                v-on:keyup.enter="onLogin"
                v-model.trim="password"
                :type="passwordFieldType"
                class="
                  focus:text-gray-700
                  focus:bg-white
                  focus:border-green-600
                  focus:outline-none
                  focus:shadow-none border border-solid border-main-blue-50 rounded-lg box-border block w-full px-4 py-3 m-0 text-base font-normal text-main-blue bg-white bg-clip-padding transition  ease-in-out
                "
                id="examplePassword0"
                placeholder="Пароль"
              />
              <EyeIcon
                v-if="passwordFieldType === 'password'"
                @click="switchVisibility"
                class="absolute cursor-pointer top-1/2 -translate-y-2/4 right-4"
              />
              <EyeCloseIcon
                v-if="passwordFieldType === 'text'"
                @click="switchVisibility"
                class="absolute cursor-pointer top-1/2 -translate-y-2/4 right-4"
              />
            </div>
          </div>
        </div>

        <span class="text-red-600 block" v-if="isError">{{
            errorData.message
          }}</span>
        <div class="flex mt-6" v-if="!isShowResetField">
          <button
            :disabled="(!password || !login)"
            class="
              w-6/12 mr-2 py-2
              flex justify-center items-center
              border
              bg-main-green-75 text-white rounded-lg
              not-italic font-medium text-sm
              hover:bg-main-green
            "
            @click="onLogin"
          >
            <Spinner v-if="isLoading"/>
            <span v-else>Вход</span>
          </button>
          <router-link
            class="
              button-link
              w-6/12 py-4 px-16
              flex justify-center
              bg-white text-main-green-50
              border border-solid border-main-green-50 rounded-lg
              not-italic font-medium text-sm
              hover:border-main-green
              hover:text-main-green
            "
            to="/register"
          >Регистрация
          </router-link
          >
        </div>
        <p class="text-xs">Используя данное приложение, Вы принимаете все <a href="#" @click="reveal" class="text-main-green-50 hover:text-main-green font-bold">условия и риски</a></p>
      </div>
    </div>
  </div>
</template>

<script>
import {Spinner, EyeIcon, EyeCloseIcon} from "@/utils/svg";
import useLogin from "./useLogin";
import {onMounted} from "vue";
import ModalWindow from "@/components/ModalWindow";
import {createConfirmDialog} from 'vuejs-confirm-dialog'
//import MessageBox from './dialog'


export default {
  components: { Spinner, EyeIcon, EyeCloseIcon },
  setup(props) {
    const {
      login,
      password,
      isLoading,
      isError,
      errorData,
      passwordFieldType,
      isShowResetField,
      onLogin,
      switchVisibility,
      onResetPassword,
    } = useLogin();
    let isHelp = false;
    onMounted(() => {
       isHelp = false;
    });

    const {
      reveal,
    } = createConfirmDialog(ModalWindow,
      {title:'Политика использования',
        contentUrl: '/v1/user_agreement'});

    return {
      reveal,
      isLoading,
      login,
      password,
      isError,
      errorData,
      passwordFieldType,
      isShowResetField,
      isHelp,
      // methods
      onLogin,
      switchVisibility,
      onResetPassword,
    };
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 780px) {
  .md-hide {
    display: none;
  }

  .md-width-full {
    width: 100% !important;
  }
}

.login-container {
  .login-form-container {
    label {
      @apply text-base font-normal text-main-blue not-italic leading-4;
      a {
        @apply text-main-green;
      }
    }
    
   

    .button-link {
      @apply bg-main-green text-white rounded-lg not-italic font-medium text-sm;
    }

    .reg-btn {
      @apply bg-white text-main-green border border-solid border-solid border-main-green;
    }
  }
}
</style>
