<template>
  <div class="bg-white rounded-lg">
    <div class="p-4 text-left">
      <h1 class="py-1.5 notification-title mb-4">Уведомления</h1>
      <div
        class="notification mb-4"
        v-for="(item, index) in notifications"
        :key="index"
      >
        <div class="notification-sm-container flex justify-between pb-1.5">
          <h4>{{ item.title }}</h4>
          <p>{{ item.created_at?.slice(0, 10) }}</p>
        </div>
        <p class="mt-1.5">
          {{ item.description }}
        </p>
      </div>
      <div class="text-center my-3" v-show="!notifications.length">
        Уведомление пусто!
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    notifications: {
      default: [],
    },
  },
};
</script>

<style lang="scss" scoped>
.notification-title {
  font-family: "Gilroy", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #164368;
  border-bottom: 2px solid #80d1be;
}
.notification-sm-container {
  border-bottom: 2px solid #80d1be;
  h4 {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #53647f;
  }
  p {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #afb7c3;
  }
}
.notification {
  p {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #53647f;
  }
}
</style>
