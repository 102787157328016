<template>
  <div class="flex min-h-screen h-full login-container m-0 p-0">
    <div
      class="md-hide w-full bg-cover bg-center max-h-full max-w-full relative"
      :style="{
        'background-image': `url(${require('@/assets/login/bg-login.png')})`,
      }"
    >
      <div
        class="bg-white rounded-full shadow shadow-gray-500 h-32 w-32 h-auto w-auto absolute top-1/2 left-1/2 p-4 -translate-x-2/4 -translate-y-2/4"
      >
        <img class="h-full w-full" src="@/assets/base-logo.svg" alt="" />
      </div>
    </div>
    <div
      class="min-h-full login-form-container flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 w-full md:w-7/12 bg-white"
    >
      <div class="max-w-md w-full space-y-8">
        <div class="flex items-center justify-center">
          <router-link to="/login"
            ><img class="" src="@/assets/icon/left.png" alt=""
          /></router-link>
          <h2 class="text-center w-full text-3xl font-bold">Регистрация</h2>
        </div>
        <template v-if="isShowSmsCodeInput">
          <!---->
          <div >Для верификации номера телефона {{ phoneNumber }} отправлен набор цифр, введите его в поле ниже</div>
          <InputField
            v-model:value.trim="smsCode"
            name="smsCode"
            type="text"
            label=""
            mask="######"
            placeholder="xxxxxx"
            maxlength="6"
            headClass="w-full"
          />
          <div>
            <div v-if="isDisabledResendCode">
              <span
                >Вы можете запросить код еще раз через
                {{ timer.seconds }} секунд</span
              >
            </div>
            <div v-else>
              <button
                @click="
                  restartFive();
                  onRequestCode();
                "
                v-if="!isDisabledResendCode"
              >
                Запросить код ещё раз
              </button>
            </div>
          </div>

          <!--<div class="flex justify-center mt-6 mb-4">
            <div class="w-full">
              <label
                for="inn"
                class="flex items-start form-label mb-2 text-gray-700"
              >
                Код подтверждения<span class="text-rose">*</span>
              </label>
              <input
                v-maska="'######'"
                v-model.trim="smsCode"
                maxlength="6"
                class="
                  focus:text-gray-700
                  focus:bg-white
                  focus:border-green-600
                  focus:outline-none
                  focus:shadow-none
                "
                id="inn"
              />
            </div>
          </div>-->
        </template>
        <template v-else>
          <ul
            class="nav nav-tabs justify-center flex flex-row list-none p-0 mb-4 h-14 uppercase text-main-blue"
            id="tabs-tab"
            role="tablist"
          >
            <li
              class="w-full pt-4 cursor-pointer hover:bg-main-blue-10"
              @click="user_type = 5"
              :class="{
                'border-solid border-b border-main-green-75 text-main-green':
                  user_type == 5,
              }"
              role="presentation"
            >
              <span
                href="#tabs-home"
                class="hover:text-main-green"
                id="tabs-home-tab"
                data-bs-toggle="pill"
                data-bs-target="#tabs-home"
                role="tab"
                aria-controls="tabs-home"
                aria-selected="true"
              >
                Я инвестор
              </span>
            </li>
            <li
              class="w-full pt-4 cursor-pointer hover:bg-main-blue-10"
              :class="{
                'border-solid border-b border-main-green-75 text-main-green':
                  user_type == 4,
              }"
              @click="user_type = 4"
              role="presentation"
            >
              <span
                href="#tabs-profile"
                class="hover:text-main-green"
                id="tabs-profile-tab"
                data-bs-toggle="pill"
                data-bs-target="#tabs-profile"
                role="tab"
                aria-controls="tabs-profile"
                aria-selected="false"
              >
                Я предприниматель
              </span>
            </li>
          </ul>
          <div class="tab-content" id="tabs-tabContent">
            <!-- Форма инвестора -->
            <div
              class="tab-pane fade show active"
              id="tabs-home"
              role="tabpanel"
              aria-labelledby="tabs-home-tab"
            >
              <InputPhoneField
                v-model:value.trim="phoneNumber"
                :isReq="true"
                comment="Номер телефона должен быть только операторов Республики Казахстан"
                id="telephone"
              />
              <!--
              <div class="form-group flex flex-col">
                <label class="mb-1 flex items-start">Телефон<span class="text-rose">*</span></label>
                <input
                  v-model.trim="phoneNumber"
                  v-maska="'+7 (###) ### ## ##'"
                  type="tel"
                  class="
                    focus:text-gray-700
                    focus:bg-white
                    focus:border-green-600
                    focus:outline-none
                    focus:shadow-none
                  "
                  id="telephone"
                  placeholder="+7 (---) --- -- --"
                />
              </div>
              -->
              <InputField
                v-model:value.trim="iin"
                :isReq="true"
                name="email"
                type="text"
                label="ИИН"
                mask="### ### ### ###"
                placeholder="12345678901"
                headClass="w-full"
              />
              <!--
                    <div class="form-group flex flex-col mt-6">
                      <label
                        for="inn"
                        class="flex items-start form-label mb-2 text-gray-700"
                      >
                        ИИН<span class="text-rose">*</span>
                      </label>
                      <input
                        v-maska="'############'"
                        v-model.trim="iin"
                        maxlength="12"
                        type="tel"
                        class="
                          focus:text-gray-700
                          focus:bg-white
                          focus:border-green-600
                          focus:outline-none
                          focus:shadow-none
                        "
                        id="inn"
                      />
                  </div>
                  -->
            </div>
            <!-- Форма предпренимателя -->
            <div
              class="tab-pane fade"
              id="tabs-profile"
              role="tabpanel"
              aria-labelledby="tabs-profile-tab"
            >
              <InputField
                v-model:value.trim="email"
                :isReq="true"
                name="email"
                type="email"
                label="E-mail"
                placeholder="name@example.com"
                headClass="w-full"
              />
              <!--
                      <div class="flex flex-col">
                          <label for="email-address" class="flex mb-1">Email<span class="text-rose">*</span></label>
                          <input
                            v-model.trim="email"
                            id="email-address"
                            name="email"
                            type="email"
                            autocomplete="email"
                            required
                            class=""
                            placeholder="name@example.com"
                          />
                      </div>
                    -->
              <InputField
                v-model:value.trim="iin"
                :isReq="true"
                name="iin"
                type="text"
                label="БИН"
                mask="### ### ### ###"
                placeholder="16565165156"
                headClass="w-full"
              />
              <!--
                      <div class="form-group flex flex-col mt-6">
                        <label
                          for="inn"
                          class="flex items-start form-label mb-2 text-gray-700"
                        >
                        БИН<span class="text-rose">*</span>
                        </label>
                        <input
                          v-maska="'############'"
                          v-model.trim="iin"
                          maxlength="12"
                          type="tel"
                          class="
                            focus:text-gray-700
                            focus:bg-white
                            focus:border-green-600
                            focus:outline-none
                            focus:shadow-none
                          "
                          id="inn"
                        />

                      </div>
                    -->
            </div>

            <!-- пароли -->
            <InputField
              v-model:value.trim="password"
              :isReq="true"
              errors
              comment="Пароль должен содержать не менее 8 латинских символов заглавные, малые буквы, цифры, и спец символы(!@#$%^&*)"
              name="password"
              type="password"
              label="Пароль"
              placeholder="Пароль"
              headClass="w-full"
              maxlength="30"
            />
            <InputField
              v-model:value.trim="passwordConfirm"
              :isReq="true"
              name="passwordConfirm"
              type="password"
              label="Повторите пароль"
              placeholder="Повторите пароль"
              headClass="w-full"
              maxlength="30"
            />
            <!--
              <div class="w-full">
                <label
                  for="password"
                  class="flex items-start form-label mb-2 text-gray-700"
                >
                  Пароль<span class="text-rose">*</span>
                </label>
                <div class="relative">
                  <input
                    v-model.trim="password"
                    :type="passwordFieldType"
                    class="
                      focus:text-gray-700
                      focus:bg-white
                      focus:border-green-600
                      focus:outline-none
                      focus:shadow-none
                    "
                    id="password"
                    placeholder="Пароль"
                  />
                  <EyeIcon
                    v-if="passwordFieldType === 'password'"
                    @click="switchVisibility('passwordFieldType')"
                    class="absolute cursor-pointer top-1/2 -translate-y-2/4 right-4"
                  />
                  <EyeCloseIcon
                    v-if="passwordFieldType === 'text'"
                    @click="switchVisibility('passwordFieldType')"
                    class="absolute cursor-pointer top-1/2 -translate-y-2/4 right-4"
                  />
                </div>
              </div>-->
            <!-- </div>
            <div class="flex justify-center mt-6">
              <div class="w-full">
                <label
                  for="examplePassword1"
                  class="
                    flex
                    items-start
                    form-label
                    inline-block
                    mb-2
                    text-gray-700
                  "
                >
                  Повторите пароль<span class="text-rose">*</span>
                </label>
                <div class="relative">
                  <input
                    :type="passwordRepeatFieldType"
                    class="
                      focus:text-gray-700
                      focus:bg-white
                      focus:border-green-600
                      focus:outline-none
                      focus:shadow-none
                    "
                    id="examplePassword1"
                    placeholder="Повторите пароль"
                  />
                  <EyeIcon
                    v-if="passwordRepeatFieldType === 'password'"
                    @click="switchVisibilityPR"
                    class="absolute cursor-pointer top-1/2 -translate-y-2/4 right-4"
                  />
                  <EyeCloseIcon
                    v-if="passwordRepeatFieldType === 'text'"
                    @click="switchVisibilityPR"
                    class="absolute cursor-pointer top-1/2 -translate-y-2/4 right-4"
                  />

                </div>
              </div>

            </div>-->
          </div>
        </template>

        <!--Кнопка регистрации -->
        <div class="flex justify-center w-full mt-6">
          <button
            @click="
              isShowSmsCodeInput ? onConfirmCode() : onRegister();
              restartFive();
            "
            class="w-full mr-2 py-2 flex justify-center items-center border bg-main-green-75 text-white rounded-lg not-italic font-medium text-sm hover:bg-main-green"
          >
            <Spinner class="my-1" v-if="isLoading" />
            <span class="py-2" v-else>Регистрация</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Spinner, EyeIcon, EyeCloseIcon } from "@/utils/svg";
import "tw-elements";
//import intlTelInput from "intl-tel-input";
import useRegister from "./useRegister";
import InputField from "@/components/common/InputField.vue";
import InputPhoneField from "@/components/common/InputPhoneField.vue";
import { watchEffect, onMounted, ref } from "vue";
import { useTimer } from "vue-timer-hook";
export default {
  components: { Spinner, EyeIcon, EyeCloseIcon, InputField, InputPhoneField },
  setup() {
    const {
      isShowSmsCodeInput,
      smsCode,
      phoneNumber,
      password,
      passwordConfirm,
      email,
      iin,
      user_type,
      isLoading,
      passwordFieldType,
      passwordRepeatFieldType,
      onRegister,
      onConfirmCode,
      switchVisibility,
      switchVisibilityPR,
      onRequestCode,
    } = useRegister();

    const isDisabledResendCode = ref(true);
    const time = new Date();
    time.setSeconds(time.getSeconds() + 1); // 10 minutes timer
    const timer = useTimer(time);
    const restartFive = () => {
      const time = new Date();
      time.setSeconds(time.getSeconds() + 60);
      timer.restart(time);
      isDisabledResendCode.value = true;
      console.log(isDisabledResendCode);
    };
    onMounted(() => {
      watchEffect(async () => {
        if (timer.isExpired.value) {
          console.warn("IsExpired");
          isDisabledResendCode.value = false;
          console.warn(isDisabledResendCode);
        }
      });
    });

    return {
      smsCode,
      isShowSmsCodeInput,
      phoneNumber,
      password,
      passwordConfirm,
      email,
      iin,
      user_type,
      isLoading,
      passwordFieldType,
      passwordRepeatFieldType,
      switchVisibilityPR,
      // methods
      onConfirmCode,
      onRegister,
      switchVisibility,
      timer,
      restartFive,
      isDisabledResendCode,
      onRequestCode,
    };
  },
  mounted() {
    //const input = document.querySelector("#telephone");
    /* intlTelInput(input, {
      preferredCountries: ["kz", "ru"],
      initialCountry: "kz"
    });*/
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 780px) {
  .md-hide {
    display: none;
  }

  .md-width-full {
    width: 100% !important;
  }
}

.login-container {
  .login-form-container {
    /* .nav-item {
      .nav-link {
        @apply text-main-blue font-medium leading-6 uppercase;
        @apply block;
        @apply border-x-0 border-t-0 border-b-2 border-transparent;
        @apply px-6 py-3 my-2 h-full;
        vertical-align: middle;
      }

      .active {
        @apply text-main-green border-main-green
      }
    }

    label {
      @apply text-base font-normal text-main-blue not-italic leading-4;
      a {
        @apply text-main-green;
      }
    }*/
    /*
    input {
      @apply border border-solid border-main-blue-50 rounded-lg box-border;
      @apply form-control block w-full;
      @apply px-4 py-3 m-0;
      @apply text-base;
      @apply font-normal;
      @apply text-main-blue;
      @apply bg-white bg-clip-padding;
      @apply transition;
      @apply ease-in-out;
      /*@apply focus:text-gray-700;
      @apply focus:bg-white;
      @apply focus:border-green-600;
      @apply focus:outline-none;
      @apply focus:shadow-none;*/
    /*}*/

    /* button {
      @apply bg-main-green text-white rounded-lg not-italic font-medium text-sm;
    }

    .reg-btn {
      @apply bg-white text-main-green border border-solid border-solid border-main-green;
    }*/
  }
}
</style>
