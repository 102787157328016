<template>
  <transition name="modal">
    <div class="modal-mask" @click="$emit('close')">
      <div class="modal-wrapper">
        <div class="modal-container overflow-y-auto" @click.stop>
          <div class="modal-body">
            <slot name="body">
              <div class="flex items-center justify-between mb-3">
                <h2 class="font-semibold text-lg">График платежей</h2>
                <button @click="$emit('close')">
                  <ErrorIcon class="w-6 h-6"/>
                </button>
              </div>
              <div v-if="!isLoading">
                <Table :columns="columnsGraph" :rows="details">
                  <template #index="{ item }">
                    {{ item.index }}
                  </template>
                  <template #amount="{ item }">
                    {{
                      `${numberWithSpaces(
                        parseFloat(item.amount).toFixed(2)
                      )} ₸`
                    }}
                  </template>
                  <template #return_amount="{ item }">
                    {{
                      `${numberWithSpaces(
                        parseFloat(item.return_amount).toFixed(2))} ₸`
                    }}
                  </template>
                  <template #interest="{ item }">
                    {{ item.interest ? `${item.interest}%` : "" }}
                  </template>
                  <template #status="{ item }">
                    {{ item.status == 'paid' ? `Оплачено` : item.status == 'waiting' ? 'В ожидании' : item.status }}
                  </template>
                </Table>
                <div class="text-center my-3" v-if="details?.length == 0 || !details">
                  График платежей пусто!
                </div>
              </div>
              <Loading v-else></Loading>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import {ref, onMounted} from "vue";
import api from "@/libs/axios";
import Table from "./Table.vue";
import {ErrorIcon} from "@/utils/svg";
import {numberWithSpaces} from "@/libs/helpers";
import Loading from "@/components/common/Loading";

const fetchDetails = (obj, id) => {
  const response = api.get(`/v1/detailedPaymentsGraph/${id}`)
    .then((data) => {
      obj.details = data.data?.data?.zayemshik_payments;
      if (obj.details) {
        for (let index = 0; index < obj.details.length; ++index) {
          obj.details[index].index = (index + 1);
        }
      }
    }).finally(() => {
      obj.isLoading = false;
    });
};

export default {
  components: {
    Loading,
    Table,
    ErrorIcon,
  },
  methods: {
    numberWithSpaces,
  },
  props: {
    idNode: {
      default: null,
    },
  },
  emits: ['close'],

  mounted() {
    this.isLoading=true;
    this.fetchDetails(this, this.noteId);
  },
  setup(props) {
    //const details = ref(null);
    const noteId = props.idNode;
    const columnsGraph = [
      {
        key: "index", label: "№",
        options: {
          classBody: "w-10 text-xs text-main-blue-50 text-center",
          classHead: "w-10 text-center",
        },
      },
      {
        key: "return_date", label: "Дата платежа",
        options: {
          classBody: "w-40 text-center",
          classHead: "w-40 text-center",
        },
      },
      {
        key: "paid_date", label: "Дата поступления средств",
        options: {
          classBody: "w-40 text-center",
          classHead: "w-40 text-center",
        },
      },
      {
        key: "return_amount", label: "Сумма возврата",
        options: {
          classBody: "w-40 text-center",
          classHead: "w-40 text-center",
        },
      },
      {
        key: "status", label: "Статуc",
        options: {
          classBody: "text-center",
          classHead: "text-center",
        },
      },
    ];
    return {
      noteId,
      fetchDetails,
      columnsGraph,
    };
  },
  data(){
    return {
      details:[],
      isLoading:false
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  height: 100%;
  width: 100%;
  overflow-y: auto;
}

.modal-container {
  height: 100%;
  max-width: 100vw;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  text-align: left;
}

.modal-body__block {
  background-color: #e5e5e5;
}

// transition
.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
