<template>
  <div
    class="input-layout flex flex-col items-start"
    :class="[headClass,(error)?'has-error':'']"
  >
    <label :for="name" class="mb-0 text-left">{{ label }}<span v-if="isReq === true" class="text-rose">*</span></label>
    <Field
      :value="value"
      v-maska="`${mask}`"
      @input="$emit('update:value', $event.target.value)"
      v-bind:class="error ? 'border-rose' : 'border-main-blue-35'"
      :class="[customClass]"
      :name="name"
      :id="name"
      :type="type"
      :placeholder="placeholder"
      :rules="isRequired"
      :minlength="minlength"
      :maxlength="maxlength"
    />
    <div v-if="comment" class="text-[0.5rem] mt-1 text-bg-main-blue-50">{{comment}}</div>
    <ErrorMessage :name="name" class="text-[0.8rem] text-rose"/>
  </div>
</template>

<script>
import { Field, ErrorMessage } from "vee-validate";

export default {
  components: { Field, ErrorMessage },
  props: {
    comment:{
      type:String,
      default:null
    },
    headClass:{
      type:String,
    },
    type: {
      type: String,
      default: "text",
    },
    customClass: {
      type: String,
      default: 'transition\n' +
        '        ease-in-out\n' +
        '        focus:outline-none focus:shadow-none\n' +
        '        w-full\n' +
        '        p-2\n' +
        '        text-sm\n' +
        '        border border-solid\n' +
        '        focus:border-main-green-75\n' +
        '        rounded'
    },
    isReq: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: "",
    },
    mask: {
      default: "",
    },
    error: {
      type: String,
      default: null,
    },
    maxlength:{
      type: Number,
      default: null,
    },
    minlength:{
      type: Number,
      default: null,
    }
  },
  emits: ['update:value'],
  computed:{

  },
  validations:{

  },
  update(){
    //console.log(value);
  },
  setup() {
    const isRequired = (value) => {
      if (!value) {
        return "Это поле обязательно для заполнения!";
      }
      return true;
    };
    return {
      isRequired,
    };
  },
};
</script>

<style lang="scss" scoped>
</style>
