<template>
  <section class="home container-layout md:ml-[302px] p-5" v-if="userData">
    <div class="main-container flex-col">
      <!--<PageTitle title="Главная" />-->
      <Statistics
        @showModal="isShowRequisitModal = true"
        :dashboardData="dashboardData"
        :userData="userData"
        v-if="!isLoading.statistics"
      />
      <Loading v-else class="p-4 rounded bg-white mb-5
      min-h-[10rem] flex justify-center items-center"></Loading>


      <div class="p-4 rounded bg-white mb-5" v-if="projectsList?.length && !isLoading.statistics">
        <TableTitle
          :title="
            userData.user_type == 5
              ? 'Мои активные проекты'
              : userData.user_type == 2
              ? 'Активные проекты'
              : 'Мои проекты'
          "
        />
        <Table
          :columns="
            userData?.user_type == 2
              ? columnsActive
              : userData.user_type == 5
              ? columnsActiveProjects
              : columnsProjects
          "
          :rows="projectsList"
        >
          <template #name="{ item }">
            <div class="flex flex-col" :class="{'bg-red':(item.lastWaitingPayment?.DaysLeft<0)}">
              <router-link
                class="font-medium hover:text-main-green leading-4"
                :to="`/requests/${
                  userData.user_type == 5 ? item.note_id_fk : item.id
                }`"
              >
                {{ userData.user_type == 5 ? item.name : item.title }}
              </router-link>
              <span class="text-[0.6rem] text-main-green-50 leading-4 ml-2">{{
                  item.company_name
                }}</span>
            </div>
          </template>
          <template #interest="{ item }">
            {{ item.interest ? `${item.interest}%` : "" }}
          </template>
          <template #closest_payment_amount="{ item }">
            {{
              item.closest_payment_amount
                ? `${numberWithSpaces(
                parseFloat(item.closest_payment_amount).toFixed(2)
                )}₸`
                : ""
            }}
          </template>
          <template #date="{ item }">
            {{ item.created_at ? item.created_at.slice(0, 10) : "" }}
          </template>
          <template #amount="{ item }">
            {{
              item.amount
                ? numberWithSpaces(parseFloat(item.amount).toFixed(2))
                : 0
            }}₸
          </template>
          <template #month="{ item }" class="text-centre">
            {{ item.month ? `${item.month} мес` : "мес" }}
          </template>
          <template #status="{ item }">
            <WarningIcon
              v-show="item.status_id_fk == 1 || item.status_id_fk == 3"
            />
            <ErrorIcon v-show="item.status_id_fk == 6"/>
            <SuccessIcon
              v-show="item.status_id_fk == 2 || item.status_id_fk == 4"
            />
          </template>
          <template #remain="{ item }" v-if="userData.user_type == 5">
            <ProggressBar
              :month="`${item.month - item.remain}`"
              :bar-procent="item.remain/item.month*100"
              :success="item.remain == 0 ? true : false"
            />
          </template>
          <template #actions="{ item }">
            <ViewIcon
              v-if="userData.user_type == 4"
              class="cursor-pointer"
              @click="ShowGraphModal(item.id)"
            />
          </template>
        </Table>
      </div>
      <Loading v-if="isLoading.statistics" class="p-4 rounded bg-white mb-5
      min-h-[10rem] flex justify-center items-center"></Loading>
      <div class="p-4 rounded bg-white mb-5"
           v-if="graphData && userData?.user_type == 5 && !isLoading.graphReturn"
      >
        <!--TODO Чтото как то извращенно-->
        <TableTitle title="График возврата по проектам"/>
        <div v-if="graphData.length">
          <TableGraph :columns="columns" :rows="graphData">
            <template #title="{ item }">
              {{ item.title }}
            </template>
            <template v-for="month in yearMonthesList" v-slot:[month]="{ item }">
              <span class="flex items-center gap-x-2">
                <CheckSuccessIcon
                v-show="item.ps[month]?.['status'] == 'paid'"
                />
                <WaitingIcon v-show="item.ps[month]?.['status'] == 'waiting' && item.ps[month]?.['prosrochka'] == false"/>
                <!-- <WarningIcon v-show="item.ps[month]?.['status'] == 'waiting' && item.ps[month]?.['prosrochka'] == true"/> -->
                <span
                  :class="{
                    'text-yellow-500': item.ps[month]?.['status'] == 'waiting' &&  item.ps[month]?.['prosrochka'] == false,
                    'text-red-500': item.ps[month]?.['status'] == 'waiting' &&  item.ps[month]?.['prosrochka'] == true,
                    'text-black': item.ps[month]?.['status'] == 'paid',
                  }"
                  >
                  {{  numberWithSpaces(parseFloat(item.ps[month]?.['return_amount']  ?? 0 ).toFixed(2)) }}₸
                </span>
              </span>
            </template>
           
          </TableGraph>
        </div>
        <p v-else>Попробуйте инвестировать в проект</p>
      </div>
    </div>
    <Loading v-if="isLoading.graphReturn" class="p-4 rounded bg-white mb-5
    min-h-[10rem] flex justify-center items-center"></Loading>

    <!-- <Notifications :notifications="notifications" /> -->
    <RequisitModal
      v-if="isShowRequisitModal"
      :requisits="requisits"
      :amount="dashboardData.next_payment.return_amount"
      @close="isShowRequisitModal = false"
    />
    <GraphModal
      v-if="isShowGraphModal"
      :id-node="singleNote"
      @close="HideGraphModal"
    />
  </section>
</template>

<script>
import {onMounted, ref} from "vue";
import {useAuth} from "@/store/auth";
import PageTitle from "@/components/PageTitle";
import TableTitle from "@/components/TableTitle";
import Statistics from "@/components/common/Statistics.vue";
import Table from "@/components/common/Table.vue";
import TableGraph from "@/components/common/TableGraph.vue";
import ProggressBar from "@/components/ProggressBar.vue";
import Notifications from "@/components/Notifications.vue";
import RequisitModal from "@/components/common/RequisitModal.vue";
import GraphModal from "@/components/common/GraphModal.vue";
import {numberWithSpaces} from "@/libs/helpers";
import {
  SuccessIcon,
  ErrorIcon,
  ViewIcon,
  CheckSuccessIcon,
  WarningIcon,
  WaitingIcon,
} from "@/utils/svg";
import useDashboard from "./useDashboard";
import Loading from "../../components/common/Loading.vue";

export default {
  components: {
    PageTitle,
    TableTitle,
    Statistics,
    Table,
    TableGraph,
    ProggressBar,
    Notifications,
    RequisitModal,
    GraphModal,
    SuccessIcon,
    ErrorIcon,
    ViewIcon,
    WarningIcon,
    CheckSuccessIcon,
    WaitingIcon,
    Loading,
  },
  methods: {
    numberWithSpaces,
    HideGraphModal: function () {
      this.isShowGraphModal = false
    },
    ShowGraphModal: function (id) {
      this.isShowGraphModal = true;
      this.singleNote = id;
    },
  },
  setup() {
    const auth = useAuth();
    const {
      isShowRequisitModal,
      isShowGraphModal,
      singleNote,
      requisits,
      notifications,
      columns,
      columnsActive,
      columnsProjects,
      columnsActiveProjects,
      projectsList,
      dashboardData,
      graphData,
      isLoading,
      fetchDashboard,
      fetchGraph,
      fetchProjects,
      fetchRequsists,
      fetchNotifications,    graphTable,
    yearMonthes, 
    monthes,
    monthesEng,
    yearMonthesList

    } = useDashboard();

    onMounted(() => {
      if (auth.userData?.user_type == 5) {
        fetchGraph();
      }
      if (auth.userData?.user_type == 4) {
        fetchRequsists();
      }
      fetchNotifications();
      fetchDashboard();
      fetchProjects();
    });

    const findMonth = (project, month) => {
      // TODO функция дофига раз создает копий
      let res = project.investor_payments.find(
        (item) => item.return_date.slice(5, 7) == month
      );
      if (res) {
        res.return_amount = parseFloat(res.return_amount)?.toFixed(2);
        return res;
      } else {
        let ret = {status: "not found", return_amount: 0};
        return ret;
      }
    };

    return {
      singleNote,
      requisits,
      notifications,
      isShowRequisitModal,
      isShowGraphModal,
      columns,
      columnsActive,
      columnsProjects,
      columnsActiveProjects,
      graphData,
      dashboardData,
      projectsList,
      isLoading,
      userData: auth.userData,
      findMonth,    graphTable,
    yearMonthes, 
    monthes,
    monthesEng,
    yearMonthesList

    };
  },
};
</script>

<style lang="scss">
@media print {
  html,
  body {
    height: 100%;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  }
}
</style>
