<template>
  <div
    class="
      dashboard-container
      relative
      bg-white
      rounded-lg
      w-full
      justify-between
      flex
      items-end
    "
  >
    <img src="@/assets/mainBar/income.png" />
    <div class="dashboard-text  items-center p-2 text-center h-32 centerall">
      <div class="baga">
      <h1 class="text-lg font-bold text-main-blue">{{investorsCount}}</h1>
      <p class="text-xs font-light text-main-blue-50 w-full">Инвесторов</p>
      </div>
      <div class="baga">
      <h1 class="text-lg font-bold text-main-blue">{{zayemshiksCount}}</h1>
      <p class="text-xs font-light text-main-blue-50 w-full">Заемщиков</p>
      </div>
    </div>
    
  </div>
</template>

<script>
import { PrintIcon } from "@/utils/svg";
export default {
  name: "MainBarUsersCountDashboard",
  components: { PrintIcon },
  props: {
    id: String,
    title: {
      default: null,
    },
    subtitle: String,
    investorsCount: String, 
    zayemshiksCount: String,
    stData: Array,
    stMonth: Array,
    type: String,
    withDashboard: Boolean,
    width: {
      type: Number,
      default: 100,
    },
    height: {
      type: Number,
      default: 80,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    printBtn: {
      default: false,
    },
  },
  data() {
    return {
      options: {
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: true,
        },
        chart: {
          id: "Chart_" + this.id,
          toolbar: {
            show: false,
          },
        },
        xaxis: {
          categories: this.stMonth,
          enabled: false,
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        tooltip: {
          x: {
            show: false,
          },
          y: {
            title: {
              formatter: function formatter(val) {
                return "";
              },
            },
          },
        },
        colors: ["#cccccc"],
        stroke: {
          curve: "smooth",
          width: 1,
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0,
            //stops: [0, 90, 100]
          },
        },
        grid: {
          //borderColor: theam(),
          padding: {
            left: -10,
            right: 0,
            bottom: -10,
            top: 0,
          },
          xaxis: {
            lines: {
              show: false,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
      },
      series: [
        {
          name: "" + this.title,
          data: this.stData,
        },
      ],
    };
  },
  methods: {
    onPrint() {
      this.$emit("showModal");
      setTimeout(() => {
        window.print();
      }, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
    .baga{
      display: flex;
      flex-direction: column;
      margin-right: 20%;
    }
    .centerall {
      justify-content: center;
      text-align: center;
      display:flex;
    }
</style>
