<template>
  <section v-if="dashboardData">
    <div class="mb-5 gap-5 flex flex-col lg:flex-row">
      <div class="w-full dashboard-container">
        <main-bar-dashboard
          :with-dashboard="true"
          type="activePrice"
          :title="
            userData?.user_type == 2
              ? `${numberWithSpaces(dashboardData.total_loan_amount)}₸`
              : userData?.user_type == 4
              ? `${numberWithSpaces(dashboardData.debts)}₸`
              : dashboardData.actives
              ? `${numberWithSpaces(dashboardData.actives)}₸`
              : '0 ₸'
          "
          :subtitle="
            userData?.user_type == 2
              ? 'Общая сумма займа на платформе'
              : userData?.user_type == 4
              ? 'Текущая задолженность'
              : 'Общая сумма активов'
          "
          :stData="dashboardData.activesData"
          :stMonth="dashboardData.activesMonthes"
        />
      </div>
      <div class="w-full dashboard-container">
        <main-bar-dashboard
          v-if="userData?.user_type != 2"
          :with-dashboard="userData?.user_type == 5 ? true : false"
          type="income"
          :title="
            userData?.user_type == 2
              ? dashboardData.zayemshiks_count
              : userData?.user_type == 4
              ? `${numberWithSpaces(
                  dashboardData.next_payment?.return_amount
                )}₸`
              : dashboardData.average_interest
              ? `${dashboardData.average_interest}%`
              : '0%'
          "
          :subtitle="
            userData?.user_type == 2
              ? 'Общее количество предпринимателей'
              : userData?.user_type == 4
              ? 'Ближайший платеж '
              : 'Средняя доходность по портфелю'
          "
          :stData="dashboardData.interestData"
          :stMonth="dashboardData.interestMonthes"
          :printBtn="userData?.user_type == 4 ? true : false"
          @showModal="$emit('showModal')"
        />
        <MainBarUsersCountDashboard
          v-if="userData?.user_type == 2"
          :with-dashboard="userData?.user_type == 5 ? true : false"
          type="income"
          :investorsCount="dashboardData.investors_count"
          :zayemshiksCount="dashboardData.zayemshiks_count"
          @showModal="$emit('showModal')"
        />
      </div>
      <div v-if="userData?.user_type == 5" class="w-full dashboard-container">
        <main-bar-dashboard
          :with-dashboard="false"
          type="totalPrice"
          :title="
            dashboardData.total_amount
              ? `${numberWithSpaces(dashboardData.total_amount)}₸`
              : '0₸'
          "
          :stData="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]"
          subtitle="Общая сумма инвестиций"
        />
      </div>
    </div>
    <div class="flex flex flex-col lg:flex-row">
      <!--Секторальная статистика-->
      <div
        class="w-full lg:basis-7/12 p-4 donuts-dashboard rounded bg-white mb-5 mr-5"
        v-if="dashboardData.conversion?.length"
      >
        <table-title title="Секторальная статистика"></table-title>
        <Doughnut
          class="h-32"
          :chart-options="chartOptions"
          :chart-data="chardData"
        />
      </div>
      <!--Последние движение денег-->
      <div class="grow p-4 rounded bg-white mb-5">
        <div class="statistic-dash-container flex items-start">
          <div class="text-left static-container mr-4">
            <div class="mb-4 static-title">
              <h3 class="text-lg font-bold text-main-blue">
                {{
                  userData?.user_type == 2
                    ? `${numberWithSpaces(dashboardData.total_loan_amount)}₸`
                    : userData?.user_type == 5
                    ? `${numberWithSpaces(dashboardData.total_income)}₸`
                    : `${numberWithSpaces(dashboardData.total_turnover)}₸`
                }}
              </h3>
              <p class="text-xs font-light text-main-blue-50">
                {{
                  userData.user_type == 5
                    ? "общий доход за весь период"
                    : "Общая задолженность"
                }}
              </p>
            </div>
            <div class="flex gap-2">
              <div
                class="h-10 w-10"
                :class="{
                  'bg-main-blue-10 rotate-90': dashboardData.this_month == 0,
                  'bg-green': dashboardData.this_month > 0,
                  'bg-rose rotate-180': dashboardData.this_month < 0,
                }"
              >
                <img class="w-full" src="@/assets/icon/up-arrow.png" alt="" />
              </div>
              <div>
                <h3 class="text-lg font-bold text-main-blue">
                  {{
                    dashboardData.this_month
                      ? numberWithSpaces(dashboardData.this_month)
                      : 0
                  }}₸
                </h3>
                <p class="text-xs font-light text-main-blue-50">
                  в этом месяце
                </p>
              </div>
            </div>
          </div>
          <!--Таблица -->
          <div
            class="sum-in-month-table-container"
            v-if="dashboardData.payments?.length"
          >
            <div class="flex flex-col sum-in-month-table">
              <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="inline-block min-w-full sm:px-6 lg:px-8">
                  <div class="overflow-hidden">
                    <table class="min-w-full border text-center">
                      <tbody>
                        <tr
                          class="border-b"
                          v-for="(item, index) in dashboardData.payments"
                          :key="index"
                        >
                          <td
                            class="p-2 whitespace-nowrap text-xs font-medium text-main-blue-50 border-r"
                          >
                            {{ item.date }}
                          </td>
                          <td
                            class="p-2 whitespace-nowrap text-xs font-medium border-r"
                            :class="
                              item.sender_iin == userData.iin
                                ? 'text-rose'
                                : 'text-green'
                            "
                          >
                            {{
                              item.sender_iin == userData.iin
                                ? `-${numberWithSpaces(item.amount)}`
                                : `+${numberWithSpaces(item.amount)}`
                            }}₸
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import TableTitle from "@/components/TableTitle";
import MainBarDashboard from "@/components/MainBarDashboard";
import MainBarUsersCountDashboard from "@/components/MainBarUsersCountDashboard";
import {numberWithSpaces} from "@/libs/helpers";
import { Doughnut } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  ArcElement,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  ArcElement
);
export default {
  components: {
    Doughnut,
    MainBarDashboard,
    MainBarUsersCountDashboard,
    TableTitle,
  },
  methods: {
    numberWithSpaces,
  },
  props: {
    width: {
      type: Number,
      default: 200,
    },
    /*height: {
      type: Number,
      default: 200,
    },*/
    cssClasses: {
      default: "h-44",
      type: String,
    },
    dashboardData: {
      default: null,
    },
    userData: {
      default: null,
    },
  },
  created: function () {
    //console.log(numberWithSpaces('100000000'));
  },
  data() {
    return {
      // chardData: {
      //   labels: ["VueJs", "ReactJs", "AngularJs"],
      //   datasets: [
      //     {
      //       data: [40, 20, 12],
      //       backgroundColor: ["#164368", "#AEBDCA", "#50728E"],
      //     },
      //   ],
      // },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: true,
            position: "right",
            align: "end",
            labels: {
              boxWidth: 10,
              pointStyleWidth: "circle",
              color: "#8C98A9",
            },
          },
        },
      },
    };
  },
  computed: {
    chardData() {
      return {
        labels: this.dashboardData?.conversion.map((item) => item.title),
        datasets: [
          {
            data: this.dashboardData?.conversion.map((item) => item.total),
            backgroundColor: ["#164368", "#AEBDCA", "#50728E"],
          },
        ],
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 300px) and (max-width: 699px) {
  .d-none-mob {
    display: none;
  }
  .ml-none-mob {
    margin-left: 0 !important;
  }
  /* .donuts-dashboard {
    width: 100% !important;
  }*/
  .statistic-dash-container {
    flex-direction: column !important;
    .static-container {
      display: flex !important;
      .static-title {
        margin-right: 1rem !important;
      }
      h4 {
        font-size: 1rem !important;
      }
      .statistic-indicator {
        max-width: 60px !important;
        max-height: 60px !important;
      }
    }
    .sum-in-month-table-container {
      width: 100% !important;
    }
  }
}
@media only screen and (min-width: 600px) and (max-width: 1000px) {
  /*.donuts-dashboard {
    width: 100% !important;
  }*/
  .statistic-dash-container {
    flex-direction: column;
    .static-container {
      display: flex;
      .static-title {
        margin-right: 1rem !important;
      }
      h4 {
        font-size: 1rem;
      }
      .statistic-indicator {
        max-width: 60px;
        max-height: 60px;
      }
    }
    .sum-in-month-table-container {
      width: 100% !important;
    }
  }
}
.sum-in-month-table {
}
.static-container {
  /*h1 {
    font-family: "Gilroy", serif;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 46px;
    color: #1a3154;
  }
  h4 {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #1a3154;
  }
  .sub-price {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #8c98a9;
  }
  .sub-title-statistic {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #8c98a9;
  }
  .statistic-indicator {
    background-color: #00a47d;
    box-shadow: 0 1px 4px rgba(0, 164, 125, 0.25),
      0 3px 12px 2px rgba(0, 164, 125, 0.35);
    border-radius: 6px;
  }*/
}
</style>
