import { useAuth } from "@/store/auth";
import axios from "axios";

let config = {
  baseURL: "https://portal.crowdlender.kz/api",

};
const api = axios.create(config);

api.interceptors.request.use((config) => {
  if (localStorage.getItem("auth")) {
    var auth = JSON.parse(localStorage.getItem("auth"));
    var { accessToken } = auth;

    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
      axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
    }
  }

  return config;
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const auth = useAuth();
    // catch if token is expired
    if (error.response.status == 401) {
      console.log("expired");
      auth.onLogout();
    }
    return Promise.reject(error);
  }
);

export default api;
