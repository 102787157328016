<template>
  <div
    class="
      dashboard-container
      relative
      bg-white
      rounded-lg
      w-full
      justify-between
      flex
      items-start
    "
  >
    <img
      v-show="type === 'activePrice'"
      src="@/assets/mainBar/activePrice.png"
    />
    <img v-show="type === 'income'" src="@/assets/mainBar/income.png" />
    <img
      v-show="type === 'totalPrice'"
      src="@/assets/mainBar/totalPrice.png"
      alt=""
    />
    <div class="dashboard-text flex flex-col items-end p-2 text-right h-32">
      <h1 class="text-lg font-bold text-main-blue">{{ title }}</h1>
      <p class="text-xs font-light text-main-blue-50 w-full">{{ subtitle }}</p>
      <button
        @click="onPrint"
        v-if="printBtn"
        class="
          flex
          items-center
          border border-primary
          w-20
          rounded-lg
          gap-x-2
          mt-auto
          p-2
        "
      >
        <PrintIcon /> <span class="text-xs text-teal-600">Печать</span>
      </button>
    </div>
    <div
      v-show="withDashboard"
      class="z-50 absolute w-full bottom-0 left-0 h-[5rem]"
    >
      <apexchart
        class="w-full"
        height="100%"
        type="area"
        :options="options"
        :series="series"
      >
      </apexchart>
    </div>
  </div>
</template>

<script>
import { PrintIcon } from "@/utils/svg";
export default {
  name: "MainBarDashboard",
  components: { PrintIcon },
  props: {
    id: String,
    title: {
      default: null,
    },
    subtitle: String,
    stData: Array,
    stMonth: Array,
    type: String,
    withDashboard: Boolean,
    width: {
      type: Number,
      default: 100,
    },
    height: {
      type: Number,
      default: 80,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    printBtn: {
      default: false,
    },
  },
  data() {
    return {
      options: {
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: true,
        },
        chart: {
          id: "Chart_" + this.id,
          toolbar: {
            show: false,
          },
        },
        xaxis: {
          categories: this.stMonth,
          enabled: false,
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        tooltip: {
          x: {
            show: false,
          },
          y: {
            title: {
              formatter: function formatter(val) {
                return "";
              },
            },
          },
        },
        colors: ["#cccccc"],
        stroke: {
          curve: "smooth",
          width: 1,
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0,
            //stops: [0, 90, 100]
          },
        },
        grid: {
          //borderColor: theam(),
          padding: {
            left: -10,
            right: 0,
            bottom: -10,
            top: 0,
          },
          xaxis: {
            lines: {
              show: false,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
      },
      series: [
        {
          name: "" + this.title,
          data: this.stData,
        },
      ],
    };
  },
  methods: {
    onPrint() {
      this.$emit("showModal");
      setTimeout(() => {
        window.print();
      }, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
