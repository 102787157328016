<template>
  <div class="page-title mb-5" :class="{ 'page-title__border': component == true }">
    <a class="cursor-pointer" @click="$router.go(-1)" v-show="withBackButton">
      <BackIcon />
    </a>
    <h1 class="text-left">{{ title }}</h1>
  </div>
</template>

<script>
import { BackIcon } from '@/utils/svg'
export default {
  name: 'PageTitle',
  components: { BackIcon },
  props: {
    title: String,
    component: {
      type: Boolean,
      default: false
    },
    withBackButton: {
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.page-title {
  display: flex;
  align-items: center;
  gap: 0 20px;

  h1 {
    color: #161E54;
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
  }

  &__border {
    border-bottom: 2px solid #80D1BE;
  }
}
</style>
