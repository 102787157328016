<template>
    <div class="flex flex-col overflow-x-auto">
      <div class="inline-block min-w-full bg-white">
        <div class="overflow-auto">
          <table
            class="min-w-full table-container border border-collapse overflow-scroll
            text-center text-sm ">
            <thead
              v-if="columns"
              class="font-bold text-main-blue-75 text-center bg-main-blue-10 overflow-scroll"
            >
              <tr>
                <th
                  v-for="(item, index) in columns"
                  :key="index"
                  scope="col"
                  class="px-4 py-4"
                  :class="[item.options?.classHead]"
                >
                  {{ item.label }}
                </th>
              </tr>
            </thead>
            <tbody v-if="rows" class="text-left font-medium text-main-blue overflow-scroll">
              <tr
                class=""
                v-for="(element, index) in rows"
                :key="index"
                :class="[element.options?.classBody?element.options?.classBody:'even:bg-main-blue/10']"
              >
                <td
                  v-for="(item, index) in columns"
                  :key="index"
                  class="px-8 py-2 whitespace-nowrap"
                  :class="[item.options?.classBody]"
                >
                  <slot
                    :item="element"
                    v-if="item.key === 'actions'"
                    name="actions"
                  ></slot>
                  <slot :item="element" :name="item.key" v-else>
                    {{ element[item.key] }}
                  </slot>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "Table",
    props: {
      rows: {
        default: [],
        type: Array,
      },
      columns: {
        default: [],
        type: Array,
      },
    },
    
    data() {
      return {
        page: 0,
        limit: 10,
      };
    },
  };
  </script>
  
  <style lang="scss" scoped>
  @media only screen and (min-width: 600px) and (max-width: 1000px) {
    .project-table-container {
      overflow-x: scroll !important;
    }
  }
  
  thead {
    /*background-color: #e8eaee;
    box-shadow: inset 0px -2px 0px rgba(0, 0, 0, 0.25);*/
  
    th {
      /*color: #1a3154;
      font-family: "Inter", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;*/
    }
  }
  </style>
