import { useAuth } from "@/store/auth";
import { ref } from "vue";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";

export default function useLogin() {
  const auth = useAuth();
  const router = useRouter();
  // Get toast interface
  const toast = useToast();
  // variables
  const login = ref(null);
  const password = ref(null);
  const isLoading = ref(false);
  const isError = ref(false);
  const errorData = ref(null);
  const passwordFieldType = ref("password");
  const isShowResetField = ref(false);

  const switchVisibility = () =>
    (passwordFieldType.value =
      passwordFieldType.value === "password" ? "text" : "password");

  const onLogin = async () => {
    isLoading.value = true;
    try {
      if (password.value == null || login.value == null) {
        throw "Поля Логин/Пароль не заполненны";
      }
      let reqData = {
        password: password.value,
      };

      if (login.value?.includes("@")) {
        reqData = {
          ...reqData,
          email: login.value,
        };
      } else {
        reqData = {
          ...reqData,
          phone: "7" + login.value?.slice().substr(-10),
        };
      }

      const request = await auth.useLogin(reqData);

      if (request.data?.success) {
        auth.accessToken = request.data?.token;
        return onFetchProfile();
      }
      isError.value = true;
      errorData.value = request.response.data;
    } catch (error) {
      console.log(error);
    } finally {
      isLoading.value = false;
    }
  };

  const onFetchProfile = async () => {
    const response = await auth.fetchProfile();

    if (response.status == 200) {
      auth.userData = response.data?.data;
      router.push("/");
      return;
    }
    toast.error(response.response?.data?.message, {
      timeout: 2000,
    });
  };

  const onResetPassword = async () => {
    isLoading.value = true;
    console.log(login);
    let reqData = {};
    try {
      if (!login.value || login.value?.length < 5) {
        throw "Не понятно для кого востанавливать пароль";
      }
      if (login.value?.includes("@")) {
        if (
          login.value.match(
            /^[A-Za-z0-9\._%+-]+@[A-Za-z0-9\.-]+\.[A-Za-z]{2,4}/
          ) == null
        )
          throw "Введите корректный Email";
        reqData = {
          email: login.value,
        };
      } else {
        login.value = "7" + login.value.replace(/[^\d]/g, "").substr(-10);
        if (login.value.length != 11) throw "Введите корректный номер телефона";
        reqData = {
          phone: login.value,
        };
      }
    } catch (e) {
      toast.error(e.toString(), {
        timeout: 2000,
      });
      isLoading.value = false;
      return;
    }
    const response = await auth.resetPassword(reqData);

    if (response) {
      isLoading.value = false;
      toast.success(response.data?.message, {
        timeout: 2000,
      });
      isShowResetField.value = false;
    }
  };

  return {
    login,
    password,
    isLoading,
    isError,
    errorData,
    passwordFieldType,
    isShowResetField,
    // methods
    onLogin,
    switchVisibility,
    onResetPassword,
  };
}
