<template>
  <div
    class="input-layout flex flex-col items-start"
    :class="{ 'has-error': error }">
    <label class="mb-0 text-left">{{label}} <span class="text-rose">*</span></label>
    <Field
      :value="value"
      v-maska="'+7 (###) ### ## ##'"
      @input="$emit('update:value', $event.target.value)"
      v-bind:class="error ? 'border-rose' : 'border-main-blue-35'"
      class="transition ease-in-out
        focus:outline-none focus:shadow-none
        w-full p-2 text-sm border border-solid focus:border-main-green-75
        rounded"
      name="phone"
      type="tel"
      placeholder="+7 (---) --- -- --"
      :id="id"
      :rules="isRequired"
    />
    <div class="text-[0.5rem] mt-1 text-bg-main-blue-50 text-center w-full">{{comment}}</div>
    <ErrorMessage name="Phone" class="text-[0.9rem] text-rose" />
  </div>
</template>

<script>
import { Field, ErrorMessage } from "vee-validate";
import intlTelInput from "@/libs/input-tel";
import { onMounted, getCurrentInstance  } from "vue";


export default {
  components: { Field, ErrorMessage },
  props: {
    comment:{
      type:String,
      default:null
    },
    isReq: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default:"telephone"
    },
    error: {
      default: null,
    },
    label:{
      default:'Телефон'
    }
  },
  emits: ['update:value','update:error'],
  setup() {
    const isRequired = (value) => {
      if (!value) {
        return "Это поле обязательно для заполнения!";
      }
      return true;
    };
    onMounted( () => {
      let input_id = "#" + getCurrentInstance().props.id
      const input = document.querySelector(input_id);

      intlTelInput(input, {
        preferredCountries: ["kz"],
        initialCountry: "kz"
      });
    });

    return {
      isRequired,
    };
  }


};
</script>

<style lang="scss" scoped>
</style>
