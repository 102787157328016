import {reactive, ref} from "vue";
import api from "@/libs/axios";
import {useAuth} from "@/store/auth";
// import { useToast } from "vue-toastification";

export default function useDashboard() {
  // Get toast interface
  //   const toast = useToast();
  const auth = useAuth();
  const isLoading=reactive(
    {
      statistics:false,
      myProject:false,
      graphReturn:false
    }
  );
  // variables
  const dashboardData = ref(null);
  const graphData = ref(null);
  const graphTable = {};
  const yearMonthes = {};
  const yearMonthesList = [];
  const projectsList = ref(null);
  const requisits = ref(null);
  const notifications = ref([]);
  const singleNote = ref(null);
  const isShowRequisitModal = ref(false);
  const isShowGraphModal = ref(false);

  // const rawDataTransfer = (data) => {
  //     for (let i = 0; i < data.length; i++) {
  //         const element = JSON.parse(JSON.parse(data[i].account_statement_results));
  //         // console.log(element)
  //         for (const key in element) {
  //             const obj = {
  //                 ...element[key],
  //                 date: key
  //             }
  //             rowData.value.push(obj)
  //         }
  //     }
  // }
  // fetchGraph();
  // console.log(graphData.value)
  const columns = [
    {
      key: "title",
      label: "Проект",
      options: {
        classHead: "text-left visible",
        classBody: "text-left visible"
      },
    },
    // { key: 'january', label: 'Январь' },
    // { key: 'february', label: 'Февраль' },
    // { key: 'march', label: 'Март' },
    // { key: 'april', label: 'Апрель' },
    // { key: 'may', label: 'Май' },
    // { key: 'june', label: 'Июнь' },
    // { key: 'july', label: 'Июль' },
    // { key: 'august', label: 'Август' },
    // { key: 'september', label: 'Сентябрь' },
    // { key: 'october', label: 'Октябрь' },
    // { key: 'november', label: 'Ноябрь' },
    // { key: 'december', label: 'Декабрь' },
  ];

  const month = [
    "january",
    "february",
    "march",
    "april",
    "may",
    "june",
    "july",
    "august",
    "september",
    "october",
    "november",
    "december",
  ];
  const monthTr = [
    "Январь",
    "Февраль",
    "Март",
    "Апрель",
    "Май",
    "Июнь",
    "Июль",
    "Август",
    "Сентябрь",
    "Октябрь",
    "Ноябрь",
    "Декабрь",
  ];
  const monthes = {
    1: 'Январь',
    2: 'Февраль',
    3: 'Март',
    4: 'Апрель',
    5: 'Май',
    6: 'Июнь',
    7: 'Июль',
    8: 'Август',
    9: 'Сентябрь',
    10: 'Октябрь',
    11: 'Ноябрь',
    12: 'Декабрь',      
  };
  const monthesEng = {
    1: 'January',
    2: 'February',
    3: 'March',
    4: 'April',
    5: 'May',
    6: 'June',
    7: 'July',
    8: 'August',
    9: 'September',
    10: 'October',
    11: 'November',
    12: 'December',      
  };
  // let curMonth = new Date().getMonth();
  // for (curMonth; curMonth < 12; curMonth++) {
  //   columns.push({
  //     key: month[curMonth],
  //     label: monthTr[curMonth],
  //     options: {
  //       classBody: "text-center w-28",
  //       classHead: "w-28",
  //     },
  //   });
  // }

  const fetchDashboard = async () => {
    try {
      isLoading.statistics=true;
      const response = await api.get("/v1/dashboard");
      dashboardData.value = response.data?.data;
    } catch (error) {
      console.log(error);
    }
    isLoading.statistics=false;
  };
  const generateColumns = () => {
    for(const year in yearMonthes) {
      yearMonthes[year].forEach((month) => {
        columns.push({
          key: monthesEng[month] + year,
          label: monthes[month] + " " + year,
          options: {
            classBody: "text-center w-60",
            classHead: "w-60",
          },
        });
      })
      
      // console.log(year, yearMonthes[year])
    }
    // console.log(columns)
  }


  const fetchGraph = async () => {
    isLoading.graphReturn=true;
    try {
      const response = await api.get("/v1/graph_refund");
      graphData.value = response.data?.message;
      graphData.value.forEach((project, projectIndex) => {
        graphTable[projectIndex] = {};  
        let ps = {};
        project.investor_payments.forEach((payment, paymentIndex)=> {
          let year = payment.return_date.slice(0,4)
          if(!yearMonthes.hasOwnProperty(year)) {
            yearMonthes[year] = [];
          }
          let month = parseInt(payment.return_date.slice(5,7))
          if(!yearMonthes[year].includes(month)) {
            yearMonthes[year].push(month)
          }
          ps[monthesEng[month] + year] = {
           return_amount : payment.return_amount,
           status : payment.status,
           prosrochka: payment.prosrochka
          }
          if(!yearMonthesList.includes(monthesEng[month] + year)) {
            yearMonthesList.push(monthesEng[month] + year);
          }
          graphTable[projectIndex][monthesEng[month] + year] = payment.return_amount
        })
        project['ps'] = ps
      })
      generateColumns();

    } catch (error) {
      console.log(error);
    }
    isLoading.graphReturn=false;
  };

  const fetchProjects = async () => {
    isLoading.myProject=true;
    let url = "/v1/myProjects";

    if (auth.userData?.user_type == 5) {
      url = "/v1/myActiveProjects";
    }

    if (auth.userData?.user_type == 2) {
      url = "/v1/activeProjects";
    }

    try {
      const response = await api.get(url);
      if (auth.userData?.user_type == 5) {
        projectsList.value = response.data?.data?.data;
      } else {
        projectsList.value = response.data?.data;
      }
      //console.log(projectsList.value);
    } catch (error) {
      //console.log(error);
    } finally {
      for (let item in projectsList.value) {
        if (projectsList.value[item].lastWaitingPayment) {
          let Duration = Math.ceil(
            (Date.parse(projectsList.value[item].lastWaitingPayment?.return_date) - Date.now()) / 86400000
          );
          projectsList.value[item].lastWaitingPayment.DaysLeft = Duration;
          if (Duration < 0)
            projectsList.value[item].options = {classBody: 'bg-red-200'};
          else if (Duration < 3)
            projectsList.value[item].options = {classBody: 'bg-amber-200'};
        }
      }
      isLoading.myProject=false;
    }
  };

  const fetchRequsists = async () => {
    const response = await api.get("/v1/requisits");
    requisits.value = response.data?.data;
  };

  const fetchNotifications = async () => {
    const response = await api.get("v1/notifications");
    notifications.value = response.data?.data;
  };

  // static data

  const columnsProjects = [
    {
      key: "name",
      label: "Имя проекта",
      options: {
        classHead: "text-left",
      },
    },
    {
      key: "amount",
      label: "Сумма займа",
      options: {
        classBody: "text-center w-28",
        classHead: "w-28",
      },
    },
    {
      key: "closest_payment_date",
      label: "Дата очередного платежа",
      options: {
        classBody: "text-center text-xs w-28",
        classHead: "w-28",
      },
    },
    {
      key: "closest_payment_amount",
      label: "Сумма очередного платежа",
      options: {
        classBody: "text-center w-28",
        classHead: "w-28",
      },
    },
    {
      key: "month",
      label: "Срок займа",
      options: {
        classBody: "text-center w-28",
        classHead: "w-28",
      },
    },
    {
      key: "interest",
      label: "%",
      options: {
        classBody: "text-center w-28",
        classHead: "w-28",
      },
    },
    {
      key: "status",
      label: "Статус",
      options: {
        classBody: "text-center w-28",
        classHead: "w-28",
      },
    },
    {key: "actions", label: ""},
  ];

  const columnsActiveProjects = [
    /*    { key: 'company_name', label: 'Компания',
            options:{
                classHead:'text-left w-28'
            }
        },*/
    {
      key: "name",
      label: "Проект",
      options: {
        classHead: "text-left",
      },
    },
    {
      key: "month",
      label: "Срок займа",
      options: {
        classBody: "text-center w-28",
        classHead: "w-28",
      },
    },
    {
      key: "interest",
      label: "%",
      options: {
        classBody: "text-center w-28",
        classHead: "w-28",
      },
    },
    {
      key: "amount",
      label: "Сумма займа",
      options: {
        classBody: "text-center w-28",
        classHead: "w-28",
      },
    },
    {
      key: "date",
      label: "Дата договора",
      options: {
        classBody: "text-center text-xs w-28",
        classHead: "w-28",
      },
    },
    {key: "remain", label: "Остаток"},
    // { key: 'actions', label: '' },
  ];

  const columnsActive = [
    {key: "status", label: ""},
    {
      key: "name",
      label: "Имя проекта",
      options: {
        classHead: "text-left w-28",
      },
    },
    {
      key: "interest",
      label: "%",
      options: {
        classBody: "text-center w-28",
        classHead: "w-28",
      },
    },
    {
      key: "amount",
      label: "Сумма займа",
      options: {
        classBody: "text-center w-28",
        classHead: "w-28",
      },
    },
    {
      key: "month",
      label: "Срок",
      options: {
        classBody: "text-center w-28",
        classHead: "w-28",
      },
    },
    {
      key: "beginDate",
      label: "Дата договора",
      options: {
        classBody: "text-center text-xs w-28",
        classHead: "w-28",
      },
    },
    {key: "actions", label: ""},
  ];

  return {
    singleNote,
    notifications,
    requisits,
    isShowRequisitModal,
    isShowGraphModal,
    columns,
    columnsProjects,
    columnsActiveProjects,
    columnsActive,
    projectsList,
    dashboardData,
    graphData,
    isLoading,
    fetchDashboard,
    fetchGraph,
    fetchProjects,
    fetchRequsists,
    fetchNotifications,
    graphTable,
    yearMonthes, 
    monthes,
    monthesEng,
    yearMonthesList

  };
}
