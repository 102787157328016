<template>
  <component :is="layout">
    <router-view />
  </component>
  <DialogsWrapper />
</template>

<script>
import DefaultLayout from './layouts/DefaultLayout.vue'
import {DialogsWrapper} from 'vuejs-confirm-dialog'
export default {
  name: "App",
  components:{ DialogsWrapper },
  computed: {
    layout() {
      return this.$route.name == 'login' || this.$route.name == 'register' ? 'section' : DefaultLayout
    }
  }
};
</script>

<style lang="scss">
body {
  background-color: $bg-primary;
}

.iti {
  width: 100%;
}

.iti__selected-flag {
  padding-left: 1rem !important;
}

.iti--allow-dropdown input[type="tel"] {
  padding-left: 4rem !important;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
