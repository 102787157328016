<template>
  <div class="flex flex-row items-center justify-between flex-nowrap mx-auto" v-if="userData">
    <a @click="$emit('showSidebar')" class="w-1/5 flex flex-col items-center">
      <MenuIcon class="fill-main-color" />
      <p class="text-main-blue-50 pt-2 text-xs">Меню</p>
    </a>

    <!--Заявки-->
    <router-link to="/requests" class="w-1/5 flex flex-col items-center">
      <RocketIcon class="fill-main-color" />
      <p class="text-main-blue-50 pt-2 text-xs">Проекты</p>
    </router-link>

    <!--Главная-->
    <router-link to="/" class="w-1/5 flex flex-col items-center">
      <HomeIcon class="fill-main-color" />
      <p class="text-main-blue-50 pt-2 text-xs">Главная</p>
    </router-link>

    <router-link
      to="/port"
      class="w-1/5 flex flex-col items-center"
      v-if="userData.user_type == 5"
    >
      <PortIcon class="fill-main-color" />
      <p class="text-main-blue-50 pt-2 text-xs">История</p>
    </router-link>

    <router-link
      to="/documents"
      class="w-1/5 flex flex-col items-center"
      v-if="userData.user_type == 4"
    >
      <UserIcon class="fill-main-color" />
      <p class="text-main-blue-50 pt-2 text-xs">Документы</p>
    </router-link>

    <router-link to="/settings" class="w-1/5 flex flex-col items-center">
      <SettingIcon class="fill-main-color" />
      <p class="text-main-blue-50 pt-2 text-xs">Настройки</p>
    </router-link>
  </div>
</template>

<script>
import { useAuth } from "@/store/auth";
import { useRouter } from "vue-router";
import {
  MenuIcon,
  RocketIcon,
  HomeIcon,
  PortIcon,
  SettingIcon,
  UserIcon,
} from "@/utils/svg";
export default {
  components: {
    MenuIcon,
    RocketIcon,
    HomeIcon,
    PortIcon,
    SettingIcon,
    UserIcon,
  },
  setup() {
    const { userData, onLogout } = useAuth();
    const router = useRouter();

    const onHandleLogout = () => {
      onLogout();
      router.push("/login");
    };

    return {
      userData,
      onHandleLogout,
    };
  },
};
</script>

<style lang="scss" scoped>
.router-link-active {
  p {
    color: $primary;
  }

  svg {
    color: $primary;
  }
}
</style>
