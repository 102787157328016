<template>
  <div class="w-full h-3 rounded-lg relative progress-bar-component
        bg-main-blue-10 outline outline-offset outline-1 outline-main-blue-35">
    <div v-if="barProcent>0" class="absolute inset-0 text-xs
            font-medium text-blue-100
            text-center m-0
            leading-none rounded-lg"
         :class="[success ?'bg-main-blue-50':'bg-main-green-50']"
         :style="[['width:' + barProcent + '%']]">
      <!--, [success ? {'background-color': '#00A47D'} : {'background-color': '#FFAF0B'}]-->
    </div >
    <div :style="[barProcent>75 ? {'color': '#FFF'} : {'color': '#53647F'}]"
       class="absolute p-0
       text-[0.5rem] leading-relaxed
       inset-y-0 mr-3 right-0 my-auto ">{{ month }}</div>
  </div>
</template>

<script>
export default {
  name: 'ProgressBar',
  props: {
    month: String,
    barProcent: Number,
    success: Boolean
  },
  mounted () {}
}
</script>

<style lang="scss" scoped>
.progress-bar-component {
  /*background-color: #E5F6F2;*/
  /*position: relative;*/
  /*.success-color {
    background-color: #00A47D;
  }
  .warning-color {
    background-color: #FFAF0B;
  }*/
  p {
   /* font-family: 'Inter',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 10px;
    position: absolute;
    right: 10%;*/
  }
}
</style>
