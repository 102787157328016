<template>
  <section @click="isShowSidebar = false">
    <Sidebar
      class="md:block"
      active="1"
      :class="{ hidden: !isShowSidebar }"
      @click.stop
    />
    <router-view class="mb-20 md:mb-4" />
    <TopMenu
      @showSidebar="isShowSidebar = !isShowSidebar"
      active="1"
      class="
        fixed
        md:hidden
        h-20
        px-3
        border-solid border-t-2 border-main-green-50
        bg-white
        drop-shadow
        bottom-0
        left-0
        w-screen
        z-10
      "
      @click.stop
    />
  </section>
</template>

<script>
import Sidebar from "@/components/common/Sidebar.vue";
import TopMenu from "@/components/common/TopMenu.vue";
export default {
  components: {
    Sidebar,
    TopMenu,
  },
  data() {
    return {
      isShowSidebar: false,
    };
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        this.isShowSidebar = false;
      },
    },
  },
};
</script>

<style>
</style>
