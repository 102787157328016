import { ref } from "vue";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import api from "@/libs/axios";

export default function useLogin() {
  const router = useRouter();
  // Get toast interface
  const toast = useToast();
  // reactive vars
  const isLoading = ref(false);
  const smsCode = ref(null);
  const phoneNumber = ref("+7");
  const password = ref(null);
  const passwordConfirm = ref(null);
  const email = ref(null);
  const iin = ref(null);
  const user_type = ref(5);
  const isShowSmsCodeInput = ref(false);
  const passwordFieldType = ref("password");
  const passwordRepeatFieldType = ref("password");

  const switchVisibility = () =>
    (passwordFieldType.value =
      passwordFieldType.value === "password" ? "text" : "password");

  const switchVisibilityPR = () =>
    (passwordRepeatFieldType.value =
      passwordRepeatFieldType.value === "password" ? "text" : "password");

  const onRegister = async () => {
    try {
      if (
        user_type.value === 4 &&
        (email.value == null ||
          email.value == "" ||
          email.value.match(
            /^[A-Za-z0-9\._%+-]+@[A-Za-z0-9\.-]+\.[A-Za-z]{2,4}/
          )) == null
      )
        throw "Емаил указан не верно";
      if (
        user_type.value === 5 &&
        (phoneNumber.value == null ||
          phoneNumber.value == "" ||
          phoneNumber.value.length < 10)
      )
        throw "Телефон указан не верно";

      console.log(iin.value);
      if (iin.value == null || iin.value == "" || iin.value.length != 15)
        throw "Заполните поле ИИН/БИН";

      if (password.value == null || passwordConfirm.value == null)
        throw "Пароль не может быть пустым";
      if (
        password.value.match(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9])\S{8,30}$/
        ) == null
      )
        throw "Пароль не соответствует требованиям безопасности";

      if (password.value !== passwordConfirm.value)
        throw "Пароли не совпадают!";

      let reqData = {
        password: password.value,
        iin: iin.value.replace(/\D/g, ""),
        user_type: user_type.value,
        email: user_type.value === 4 ? email.value : undefined,
        phone:
          user_type.value === 5
            ? "7" + phoneNumber.value.replace(/\D/g, "").substr(-10)
            : undefined,
      };
      if (phoneNumber.value && iin.value && password.value) {
        isLoading.value = true;
        onRegisterUser(reqData);
        return;
      }
    } catch (e) {
      toast.error(e);
    } finally {
    }
  };

  const onRegisterUser = async (reqData) => {
    try {
      const request = await api.post("/v1/register", reqData);
      if (user_type.value === 5) {
        return await onRequestCode();
      }
      //
      toast.success(request.data?.message);
      router.push("/login");
    } catch (error) {
      toast.error(error.response?.data?.message);
    } finally {
      isLoading.value = false;
    }
  };

  const onRequestCode = async () => {
    try {
      const response = await api.post("/v1/requestCode", {
        phone: phoneNumber.value.replace(/\D/g, ""),
      });
      // show input code if user type == 5
      if (response) {
        isShowSmsCodeInput.value = true;
      }
    } catch (error) {
      toast.error(error.response?.data?.message);
    } finally {
      isLoading.value = false;
    }
  };

  const onConfirmCode = async () => {
    try {
      isLoading.value = true;
      const response = await api.post("/v1/confirmCode", {
        phone: phoneNumber.value.replace(/\D/g, ""),
        code: smsCode.value,
      });
      toast.success(response.data?.message);
      router.push("/login");
    } catch (error) {
      toast.error(error.response?.data?.message);
    } finally {
      isLoading.value = false;
    }
  };

  return {
    smsCode,
    isShowSmsCodeInput,
    phoneNumber,
    password,
    passwordConfirm,
    email,
    iin,
    user_type,
    isLoading,
    passwordFieldType,
    passwordRepeatFieldType,
    // methods
    onRegister,
    switchVisibility,
    switchVisibilityPR,
    onConfirmCode,
    onRequestCode,
  };
}
