<template>
  <transition name="modal">
    <div class="modal-mask" @click="$emit('close')">
      <div class="modal-wrapper">
        <div class="modal-container" @click.stop>
          <div class="modal-body">
            <slot name="body">
              <h2 class="font-semibold text-lg">
                Реквизиты для пополнения счета сайта
              </h2>
              <div class="modal-body__block p-3 my-2" v-if="requisits">
                <div class="flex items-center">
                  <b class="mr-4">Наименование</b>
                  <p>{{ requisits.name }}</p>
                </div>
                <!-- <div class="flex items-center mt-2">
                  <b class="mr-4">Полное наименование</b>
                  <p>Общество с ограниченной ответственностью "Продавец"</p>
                </div> -->
                <div class="flex items-center mt-2">
                  <b class="mr-4">ИНН</b>
                  <p>{{ requisits.iin }}</p>
                </div>
                <div class="flex items-center mt-2">
                  <b class="mr-4">КПП</b>
                  <p>{{ requisits.corp_schet }}</p>
                </div>
                <div class="flex items-center mt-2">
                  <b class="mr-4">ОГРН</b>
                  <p>{{ requisits.schet }}</p>
                </div>
                <div class="flex items-center mt-2">
                  <b class="mr-4">БИК</b>
                   <p>{{ requisits.bik }}</p>
                </div>
                <div class="flex items-center mt-2">
                  <b class="mr-4">Адрес</b>
                   <p>{{ requisits.city }}</p>
                </div>
                <div class="flex items-center mt-2">
                  <b class="mr-4">Наименование банка и город</b>
                 <p>{{ requisits.bank_name }}, {{ requisits.city }}</p>
                </div>
                <div class="flex items-center mt-2">
                  <b class="mr-4">Сумма</b>
                 <p>{{ amount }}</p>
                </div>
                <!-- <div class="flex items-center mt-2">
                  <b class="mr-4">Расчетный счет </b>
                  <p>40702810938000000011</p>
                </div> -->
              </div>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    requisits: {
      default: null,
    },
    amount: {
      default: 0
    }
  },
};
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  max-width: 625px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  text-align: left;

  @media only screen and (max-width: 599px) {
    padding: 10px 12px;
  }
}

.modal-body__block {
  background-color: #e5e5e5;
}
// transition
.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>