import api from "@/libs/axios";
import router from "@/router";
import { defineStore } from "pinia";

export const useAuth = defineStore("auth", {
  state: () => {
    return { accessToken: null, userData: null };
  },
  persist: {
    key: "auth",
    storage: window.localStorage,
    paths: ["accessToken", "userData"],
  },
  actions: {
    async onLogout() {
      api.post("/v1/logout");
      this.accessToken = null;
      this.userData = null;
      router.push("/login");
    },
    async useLogin(reqData) {
      try {
        api.get("/csrf-cookie");

        return await api.post("/v1/login", reqData);
      } catch (error) {
        return error;
      }
    },
    async fetchProfile() {
      try {
        return await api.get("/v1/profile/me");
      } catch (error) {
        return error;
      }
    },
    async updateProfile(reqData) {
      try {
        return await api.put("/v1/profile/edit", reqData);
      } catch (error) {
        return error;
      }
    },
    async updatePassword(reqData) {
      try {
        return await api.post("/v1/profile/changePassword", reqData);
      } catch (error) {
        return error;
      }
    },
    async resetPassword(reqData) {
      try {
        return await api.post("/v1/password/reset", reqData);
      } catch (error) {
        return error;
      }
    },
  },
});
