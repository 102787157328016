import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import api from '@/libs/axios'
import Vmask from '@/libs/v-mask'
import 'tw-elements';
// import manually
import pinia from '@/libs/pinia-persist'
import Toast from '@/libs/vue-toast'
import VueApexCharts from 'vue3-apexcharts'
import VueConfirmDialog from 'vuejs-confirm-dialog'
import Popper from "vue3-popper";
// css
import './index.scss'
import "intl-tel-input/build/css/intlTelInput.css";
import "intl-tel-input/build/js/intlTelInput.js";
import './registerServiceWorker'

const options = {
    // You can set your default options of toast here
    hideProgressBar: false
};

const app = createApp(App)
app.config.globalProperties.$axios = {...api}
app.use(VueApexCharts)
app.use(Vmask)
app.use(Toast, options)
app.use(pinia)
app.use(router)
app.use(VueConfirmDialog)
app.component("Popper", Popper);
app.component('vue-confirm-dialog', VueConfirmDialog)
app.mount('#app')
