import { createRouter, createWebHistory } from "vue-router";
import { useAuth } from "@/store/auth";
// components
import Dashboard from "@/views/home/Dashboard.vue";
import Login from "@/views/auth/Login";
import Register from "@/views/auth/Register";
import { toastInjectionKey, useToast } from "vue-toastification";
const toast = useToast();
const routes = [
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/register",
    name: "register",
    component: Register,
  },

  {
    path: "/",
    name: "dashboard",
    component: Dashboard,
  },
  {
    path: "/requests",
    name: "requests",
    component: () => import("@/views/request/Request.vue"),
  },
  {
    path: "/requests/:id",
    name: "requests-id",
    component: () => import("@/views/request/request-view/RequestView.vue"),
  },
  {
    path: "/requests/edit/:id",
    name: "requests-edit",
    component: () => import("@/views/request/request-edit/RequestEdit.vue"),
  },
  {
    path: "/request-create",
    name: "request-create",
    component: () => import("@/views/request/request-create/RequestCreate.vue"),
  },
  {
    path: "/request-invest",
    name: "request-invest",
    component: () => import("@/views/request/invest/Invest.vue"),
  },
  {
    path: "/integrations",
    name: "integrations",
    component: () => import("@/views/integration/Integration.vue"),
  },
  {
    path: "/port",
    name: "port",
    component: () => import("@/views/port/Ports.vue"),
  },
  {
    path: "/documents",
    name: "documents",
    component: () => import("@/views/documents/Documents.vue"),
  },
  {
    path: "/users",
    name: "users",
    component: () => import("@/views/users/Users.vue"),
  },
  {
    path: "/users/edit/:id",
    name: "users-edit",
    component: () => import("@/views/users/users-edit/UsersEdit.vue"),
  },
  {
    path: "/settings",
    name: "settings",
    component: () => import("@/views/settings/Settings.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,

  scrollBehavior() {
    return { top: 0 };
  },
});

const requiredUserData = [
  "company_bin",
  "company_iban",
  "company_name",
  "supervisor_iin",
  "supervisor_name",
  "supervisor_phone",
  "supervisor_surname",
  "ur_address",
  "ur_phone",
];

router.beforeEach((to, from, next) => {
  const { accessToken, userData } = useAuth();
  // handle if accessToken has
  if (to.name != "login" && to.name != "register" && !accessToken)
     return next({ name: "login" });
  if (to.name == "login" && accessToken) return next({ name: "dashboard" });
  if (accessToken && userData.user_type == 4) {
    let redirected = false;
    requiredUserData.forEach((element) => {
      if (!userData[element] && to.name == "request-create" && !redirected) {
        toast.warning("Заполните данные о компании и руководителе!");
        redirected = true;
        return next({ name: "settings" });
      }
    });
  }
  next();
});

export default router;
